import React, { useState, useEffect } from "react";
import { AiFillEye, AiFillGithub } from "react-icons/ai";
import { motion } from "framer-motion";

import { AppWrap, MotionWrap } from "../../wrapper";
import { urlFor, client } from "../../client";

import "./Work.scss";


const Work = () => {
  const worksFilterList = ["AI", "Web App", "Mobile App", "React JS", "All"];
  const [activeFilter, setActiveFilter] = useState("All");
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });
  const [works, setWorks] = useState([]);
  const [filterWork, setFilterWorks] = useState([]);
  
  useEffect(() => {
    const query = '*[_type == "works"]';

    client.fetch(query).then((data) => {
      setWorks(data);
      setFilterWorks(data);
    });
  }, []);

  const handleWorkFilter = (filterItem) => {
    setActiveFilter(filterItem);
    setAnimateCard([{y: 100, opacity: 0}]);

    setTimeout(() => {
      setAnimateCard([{y: 0, opacity: 1}]);

      if(filterItem === 'All'){
        setFilterWorks(works);

      }
      else{
        setFilterWorks(works.filter((work) => work.tags.includes(filterItem)))
      }
    }, 500);

  };

  return (
    <>
      <h2 className="head-text">
        My <span>Portfolio</span>
      </h2>

      <div className="app__work-filter">
        {worksFilterList.map(
          (filter, index) => (
            <div
              key={index}
              onClick={() => handleWorkFilter(filter)}
              className={`app__work-filter-item app__flex p-text ${
                activeFilter === filter ? "filter-active" : ""
              }`}
            >
              {filter}
            </div>
          )
        )}
      </div>

      <motion.div
        animate={animateCard}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__work-portfolio"
      >
        {filterWork.map((work, index) => (
          <div className="app__work-item app__flex" key={index}>
            <div className="app__work-img app__flex">
              <img
                src={urlFor(work.imgUrl)}
                alt={work.name}
                className="project-img"
              />
            </div>

            <div className="app__work-content app__flex">
              <h4 className="bold-text">{work.title}</h4>
              <p className="p-text" style={{ marginTop: 10 }}>
                {work.description}
              </p>

              <div className="app__work-tag app__flex">
                {work.tags.map((workTag) =>(
                  <p className="p-text">{workTag}</p>
                ))}
              </div>
            </div>
            <div className="btn-container">
              <a
                className="btn btn-color-1 project-btn"
                href={work.codeLink}
                target="_blank"
                rel="noreferrer"
              >
                <motion.div
                  whileInView={{ scale: [0, 1] }}
                  animate={animateCard}
                  transition={{ duration: 0.5, delayChildren: 0.5 }}
                  className="app__flex"
                >
                  Github
                </motion.div>
              </a>
              <a
                className="btn btn-color-2 project-btn"
                href={work.projectLink}
                target="_blank"
                rel="noreferrer"
              >
                <motion.div
                  whileInView={{ scale: [0, 1] }}
                  animate={animateCard}
                  transition={{ duration: 0.5, delayChildren: 0.5 }}
                  className="app__flex"
                >
                  Live Demo
                </motion.div>
              </a>
            </div>
          </div>
        ))}
      </motion.div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Work, 'app__works'),
  'work',
  "app__primarybg"
);
